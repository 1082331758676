<div class="card-container">

  <a class="header-back" [routerLink]="['/app']">
    <mat-icon inline=true fontSet="fa" fontIcon="fa-arrow-left" aria-label="back"></mat-icon>
    <span>My portfolios</span>
  </a> / {{portfolio.name }}
 


  <mat-toolbar>
    <h1>Optimization: {{optimName}}</h1>
  </mat-toolbar>
  <mat-card class="settings no-print">
    <mat-card-content>
      <section id="settings-section-optimized">
        <div class="card mb-4">
          <div class="card-header">
            <h1>Settings<app-info infoIndex="11"></app-info></h1>
            <div class="d-inline-block pt-4 max-width" *ngIf="dataLoaded">
              <div class="row">
                <div class="col divider-right">
                    <app-cim-datepicker class="header-select" label="Start Date" [minDate]="portfolio.start_date" [model]="optimizationData.optim_parameters.return_start_date" (modelChanged)="optimizationData.optim_parameters.return_start_date = $event;"></app-cim-datepicker>
                </div>
                <div class="col">
                    <app-cim-datepicker class="header-select" label="End Date" [minDate]="portfolio.start_date" [model]="optimizationData.optim_parameters.return_end_date" (modelChanged)="optimizationData.optim_parameters.return_end_date = $event;"></app-cim-datepicker>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Risk Free Rate</mat-label>
                    <input matInput [(ngModel)]="optimizationData.optim_parameters.risk_free_rate" type="number" step="0.01">
                    <span matSuffix>%</span>
                  </mat-form-field>
                </div>
                <div class="col">
                    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="saveInputsAndTriggerHistoricalCalc()"><span class="navigation-button">Optimize</span></button>
                </div>
              </div>
              <div class="row">
                <div class="col small">
                    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="triggerCalc('ytd')"><span class="navigation-button">YTD</span></button>
                </div>
                <div class="col small">
                    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="triggerCalc('1')"><span class="navigation-button">1 Yr</span></button>
                </div>
                <div class="col small">
                    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="triggerCalc('5')"><span class="navigation-button">5 Yr</span></button>
                </div>
                <div class="col small">
                    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="triggerCalc('10')"><span class="navigation-button">10 Yr</span></button>
                </div>
                <div class="col small">
                    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="triggerCalc('15')"><span class="navigation-button">15 Yr</span></button>
                </div>
                <div class="col mid">
                    <button class="fixed-optimize-btn" mat-flat-button color="primary" (click)="triggerCalc('start')"><span class="navigation-button">MAX</span></button>
                </div>
                <!-- <th class="text-grey">YTD</th>  -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
  <mat-card class="settings no-print">
    <mat-card-content>
      <section id="covariance-section-optimized">
        <div class="card mb-4">
          <div class="card-header">
            <h1>Covariance / Correlation <app-info infoIndex="12"></app-info></h1>
            <app-optimization-sections 
              *ngIf="optimizationData && filterLists"
              [portfolio]="portfolio"
              [optimData]="optimizationData"
              [correlationMatrixData]="correlationMatrixData"
              [covarianceMatrixData]="covarianceMatrixData"
              [correlationMatrixTableData]="correlationMatrixTableData"
              [covarianceMatrixTableData]="covarianceMatrixTableData"
              [filterLists]="filterLists"
            ></app-optimization-sections>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
  <mat-card class="settings no-print">
    <mat-card-content>
      <section id="implied-views-section-optimized">
        <div class="card mb-4">
          <div class="card-header">
            <h1>Implied Views <app-info infoIndex="13"></app-info> </h1>
            <div class="underscore">
              Top 3 largest holdings
            </div>
            <ul>
              <li *ngFor="let company of top3Holdings | slice:0:3;">

                {{company.company_name}} ({{(company.Weight*100).toFixed(2)}}%): You are expecting {{company.company_name}} to return {{(company.implied_returns*100).toFixed(2)}}%. (Mkt. Eq. {{(company.market_equilibrium*100).toFixed(2)}}%).
                 
              </li>
              
            </ul>
            <div class="underscore">
              Top 3 largest deviation from Market Equilibrium Returns
            </div>
            <ul>
              <li *ngFor="let company of top3LargestDev | slice:0:3;">

                
                {{company.company_name}} ({{(+company.mkt_eq_returns*100).toFixed(2)}}%): You expect {{company.company_name}} to {{company.mkt_eq_returns > 0 ? "outperform" : "underperform"}}  the Market Equilibrium Returns by {{(+company.mkt_eq_returns*100).toFixed(2)}}%.

              </li>
            </ul>
          </div>
          <div class="card">
            <div class="card-body">
              <app-chart-placeholder *ngIf="!forecastedReturnsTableData.length" [loaded]="forecastedReturnsTableData.length > 0"></app-chart-placeholder>
              <app-cim-datatable
                *ngIf="viewTablesLoaded && forecastedReturnsTableData.length"
                [columns]="forecastedReturnsTableColumns"
                [data]="filteredForecastedReturnsTableData"
                [footer]="footerData"
                (inputChange)="onForecastedReturnInputChange()"
              ></app-cim-datatable>
            </div>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
  <mat-card class="settings no-print">
    <mat-card-content>
      <section *ngIf="portfolio && optimizationData" id="my-views-section-optimized">
        <div class="card mb-4">
          <div class="card-header">
            <h1>My views <app-info infoIndex="14"></app-info></h1>
            <app-views-setter
              [portfolio]="portfolio"
              [sectors]="constraintLists.sectors"
              [assets]="constraintLists.assets"
              [countries]="constraintLists.countries"
              [views]="optimizationData.views"
              (optimize)="saveInputsAndTriggerHistoricalCalc()"
            ></app-views-setter>
            <app-simple-select class="ml-2" *ngIf="portfolioHasETF" [list]="typeList" label="Type" [isMultiple]="true" [model]="filter.type" (modelChanged)="filter.type = $event; onFilterChange()"></app-simple-select>
            <!-- <div class="sum-card-in-header summary-card">
              <div class="figure">{{viewReturns.forecast || 0}}%</div>
              <div class="figure-label">My Views Return</div>
            </div> -->
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
  <mat-card class="settings no-print">
    <mat-card-content>
      <section id="historical-returns-section-optimized">
        <div class="card mb-4">
          <div class="card-header">
            <h1>Combined Returns <app-info infoIndex="15"></app-info></h1>
            <app-simple-select class="ml-2" *ngIf="portfolioHasETF" [list]="typeList" label="Type" [isMultiple]="true" [model]="filter.type" (modelChanged)="filter.type = $event; onFilterChange()"></app-simple-select>
            <div class="sum-card-in-header summary-card">
              <!-- <div class="figure">{{viewReturns.historical || 0}}%</div>
              <div class="figure-label">Historical Return</div> -->
            </div>
          </div>
          <div class="card-body">
            <app-cim-datatable *ngIf="viewTablesLoaded" [columns]="historicalReturnTableColumns" [data]="filteredHistoricalReturnTableData" [sortProp]="'Weight'" sortDirection="desc"></app-cim-datatable>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
  <mat-card class="settings no-print">
    <mat-card-content>
      <section id="constraints-section-optimized">
        <app-constraint-setter
          *ngIf="optimizationData"
          [portfolio]="portfolio"
          [constraints]="optimizationData.group_constraints"
          [sectors]="constraintLists.sectors"
          [assets]="constraintLists.assets"
          [countries]="constraintLists.countries"
          (optimize)="saveInputsAndTriggerHistoricalCalc()"
        ></app-constraint-setter>

        <div>
          <div class="float-right pt-3 pr-3">
            <!-- <mat-form-field appearance="outline" class="header-select ">
              <mat-label>Type</mat-label>
              <mat-select [(ngModel)]="selectedPerformanceType" (ngModelChange)="onOptimTypeChange()">
                <mat-option [value]="''">Constrained</mat-option>
                <mat-option [value]="'unconstrained'">Unconstrained</mat-option>
                <mat-option [value]="'cost_of_constrained'">Cost of constraints</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>
          <div class="card">
            <div class="card-header">Cost of constraints<app-info infoIndex="17"></app-info></div>
            <div class="card-body">
              <table class="table m-0">
                <thead>
                <tr>
                  <td class="text">Portfolio</td>
                  <td class="text-right header-percent">Expected Return</td>
                  <td class="text-right header-percent">Expected Volatility</td>
                  <td class="text-right">Sharpe Ratio</td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of optimPerformance['optim_performance']">
                  <td class="text">Constrained</td>
                  <td class="number-col text-right">{{(item.Expected_Return*100)|number:'1.2-2'}}%</td>
                  <td class="number-col text-right">{{(item.Std_Deviation*100)|number:'1.2-2'}}%</td>
                  <td class="number-col text-right">{{(item.Sharpe_Ratio)|number:'1.2-2'}}</td>
                </tr>
                <tr *ngFor="let item of optimPerformance['optim_performance_unconstrained']">
                  <td class="text">Unconstrained</td>
                  <td class="number-col text-right">{{(item.Expected_Return*100)|number:'1.2-2'}}%</td>
                  <td class="number-col text-right">{{(item.Std_Deviation*100)|number:'1.2-2'}}%</td>
                  <td class="number-col text-right">{{(item.Sharpe_Ratio)|number:'1.2-2'}}</td>
                </tr>
                <tr *ngFor="let item of optimPerformance['optim_performance_cost_of_constrained']">
                  <td class="text">Cost of constraints</td>
                  <td class="number-col text-right" [ngClass]="{'text-red' : item.Expected_Return < 0, 'text-green' : item.Expected_Return  > 0, 'text-blue' : item.Expected_Return  == 0}">{{(item.Expected_Return*100)|number:'1.2-2'}}%</td>
                  <td class="number-col text-right " [ngClass]="{'text-red' : item.Std_Deviation < 0, 'text-green' : item.Std_Deviation  > 0, 'text-blue' : item.Std_Deviation  == 0}" >{{(item.Std_Deviation*100)|number:'1.2-2'}}%</td>
                  <td class="number-col text-right" [ngClass]="{'text-red' : item.Sharpe_Ratio < 0, 'text-green' : item.Sharpe_Ratio  > 0.1, 'text-blue' : item.Sharpe_Ratio  > 0}">{{(item.Sharpe_Ratio)|number:'1.2-2'}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
  <mat-card class="settings">
    <mat-card-content>
      <div class="card mb-4">
        <div class="card-header">
          <h1>Results<app-info infoIndex="18"></app-info></h1>
        </div>
      </div>
      <section id="results-section-optimized">
        <app-chart-placeholder [loaded]="efficientChartLoaded"></app-chart-placeholder>
        <app-efficient-frontier-chart *ngIf="efficientChartLoaded"
                                      [series]="efficientSeries"
                                      [optimWeights]="efficientOptimWeights"
                                      [optim] = "optim"
                                      [optimizationData]="optimizationData"
                                      (calculateClicked)="handleCalculate($event)">

        </app-efficient-frontier-chart>
        <div class="row">
          <div class="col-md-12">
             <h1><app-info infoIndex="19"></app-info></h1>
            <app-cim-datatable *ngIf="viewTablesLoaded"
                               [sortProp]="'allocation'"
                               [sortDirection]="'desc'"
                               [columns]="resultsTableColumns"
                               [data]="resultsTableData"
                               [footer]="resultsTableDataFooter[0]">
            </app-cim-datatable>
          </div>
        </div>

      </section>
    </mat-card-content>
        <mat-card-content>
          <section>
            <div class="row" *ngIf="dataLoaded">
               <h1><app-info infoIndex="20"></app-info></h1>
              <div class="col-md-12">
               <app-area-chart [optimizationData]="efficientOptimWeights" [volatilityLine]="volatilityLine"
                               [minVolatility]="minVolatility" [maxVolatility]="maxVolatility"
                               [volatilityLineText]="optim"
               ></app-area-chart>
             </div>
            </div>
        <div class="row" *ngIf="dataLoaded">
          <div class="col-md-6">
          <mat-card>
          <mat-card-content>
            <section id="summary-weights-donut">
              <div class="card mb-4">
                <div class="card-header">
                  <h1>Portfolio Weights<app-info infoIndex="21"></app-info></h1>
                </div>
              </div>
              <div>
                <app-donut-chart [data]="resultsTableData" [type]="'allocation'"></app-donut-chart>
              </div>
            </section>
          </mat-card-content>
        </mat-card>
          </div>

          <div class="col-md-6">
            <mat-card>
              <mat-card-content>
                <section id="summary-returns-donut">
                  <div class="card mb-4">
                    <div class="card-header">
                      <h1>Portfolio Returns<app-info infoIndex="22"></app-info></h1>
                    </div>
                  </div> 
                  <div>
                    <app-donut-chart [data]="resultsTableData"></app-donut-chart>
                  </div>
                </section>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <mat-card>
          <mat-card-content>
            <section id="summary-risk-contribution-chart">
              <div>
                  <app-risk-contribution-column-chart *ngIf="riskContributionData"
                                                      [riskContributionRaw]="riskContributionData"
                                                      [subHeader]="{portfolio: portfolio.name}">
                  </app-risk-contribution-column-chart>
              </div>
            </section>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-content>
            <section id="summary-marginal-risk-contribution-chart">
              <div>
                  <app-portfolio-return-bar-chart *ngIf="marginalRiskContributionData"
                                                  [title]="'Marginal Risk Contribution'"
                                                  [rawData]="marginalRiskContributionData">
                  </app-portfolio-return-bar-chart>
              </div>
            </section>
          </mat-card-content>
        </mat-card>


      </section>

    </mat-card-content>
  </mat-card>

</div>
