import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {PortfolioModel} from "../../../shared/models/portfolio.model";
import {portfolioTypes} from "../portfolio.service";

@Component({
  selector: 'app-manual-portfolio-form',
  templateUrl: './manual-portfolio-form.component.html',
  styleUrls: ['./manual-portfolio-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManualPortfolioFormComponent implements OnInit {
  @Input() hasETF = false;
  @Input() maxStartDate = null;
  @Input() selectedCurrencies: any[] = [];
  @Input() portfolio = new PortfolioModel({});
  
  @Input() filterLists: any = {
    markets: [],
    regions: [],
    countries: [],
    main_currencies: [],
    sectors: [],
    industries: [],
    expectedResults: null,
  }
  @Output() portfolioSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() weightChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() setAllEqual: EventEmitter<any> = new EventEmitter<any>();
  @Output() startDateSet: EventEmitter<any> = new EventEmitter<any>();

  totalHedging:string = null;
  totalHedgingCount:number = null;
  currencyCount:number = null;

  portfolioTypes = portfolioTypes;

  constructor() { }

  ngOnInit(): void {
    this.filterLists.main_currencies = this.filterLists.main_currencies.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    this.onHedgingChange();

    // Trigger market cap at start date fetch
    this.onStartDateSet();
  }

  onSavePortfolioClick() {
    this.portfolioSave.emit(this.portfolio);
  }

  onWeightTypeChange() {
    this.weightChange.emit()
  }

  onHedgingChange() {
    this.totalHedging = '';
    this.totalHedgingCount = 0;
    this.currencyCount = this.selectedCurrencies.length;

    this.selectedCurrencies.map(c => {
      if (c.hedging && c.id != this.portfolio.currency_id) {
        if (this.totalHedging) {
          this.totalHedging += ', ';
        }
        this.totalHedging += `${c.code} ${c.hedging}`
        this.totalHedgingCount++;
      }
      if (c.id == this.portfolio.currency_id) {
        this.currencyCount -= 1;
      }
    })
  }

  setAllAssetEqual() {
    this.setAllEqual.emit();
  }

  onStartDateSet() {
    this.startDateSet.emit()
  }
}
