<!-- This button triggers the overlay and is it's origin -->
<button  mat-icon-button color="primary" class="popover-info-btn" type="button" (click)="open = !open" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-icon>info</mat-icon>
</button>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="open"
        [cdkConnectedOverlayOffsetX]="-20"
        (overlayOutsideClick)="close()"
        [cdkConnectedOverlayPositions]="positionPairs"
>
    <div class="popover-card max-500">
        <button class="popover-close-btn" (click)="open = false" mat-icon-button ><mat-icon>close</mat-icon></button>
        <br />
<!--        <p class="text-semi-bold  mb-2" [innerHTML]="title|highlight:highlight"></p>-->
        <span class="max-1500" [innerHTML]="infoContent[infoIndex]"></span>
    </div>
</ng-template>
