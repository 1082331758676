import {Component, Injector} from '@angular/core';
import {ColumnType} from "../../components/cim-datatable/cim-datatable.component";
import {OptimizationComponent} from "../optimization/optimization.component";

@Component({
  selector: 'app-implied-view',
  templateUrl: './implied-view.component.html',
  styleUrls: [
    '../overview/portfolio-summary/summary-cards/summary-cards.component.scss',
    '../optimization/optimization.component.scss',
    './implied-view.component.scss'
  ]
})
export class ImpliedViewComponent extends OptimizationComponent {

  filteredForecastedReturnsTableData: any[] = [];

  forecastedReturnsTableColumns: any[] = [
    {id: 'company_name', label: 'Asset', type: ColumnType.CompanyInfo},
    {id: 'Ticker', label: 'Ticker', type: ColumnType.Simple, inputDisabled: true, canHide: true},
    {id: 'Weight', label: 'Weight', type: ColumnType.Percentage},
    {id: 'weight_marketcap', label: 'Marketcap Weights', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'volatility', label: 'Volatility', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'historical_returns', label: 'Historical Returns', type: ColumnType.Number2Decimal, suffix: '%'},
    /*{id: 'implied_returns', label: 'Implied Returns', type: ColumnType.Number2Decimal, suffix: '%'},*/
    {id: 'market_equilibrium', label: 'Market Equilibrium Returns', type: ColumnType.Number2Decimal, suffix: '%'},
    {id: 'forecasted_returns', label: 'Forecasted Return', type: ColumnType.Input, suffix: '%'},
  ];

  footerData:any = {
    company_name: 'Total expected return',
    Ticker: '',
    Weight: '',
    weight_marketcap: '',
    volatility: '',
    historical_returns: 'foot',
    market_equilibrium: 'foot',
    /*implied_returns: 'foot',*/
    forecasted_returns: 'foot',
  }

  filter: any = {
    sector: null,
    country: null,
    type: null
  }

  filterLists: any = {
    sectorList: [],
    countryList: [],
    typeList: [],
  }

  constructor(injector: Injector) {
    super(injector);
  }

  initPage() {
    if (!this.optimizationData) {
      this.fetchOptimizationData()
    }
    this.initGroupConstraintLists();
  }

  afterOptimizationDateReceived() {
    this.correlationMatrixTableData = this.extractHeatMapTableData(this.optimizationData.correlation_matrix.items, this.portfolio.securities.length);

    this.optimizationData.covariance_matrix.items = this.optimizationData.covariance_matrix.items.map((i: any) => {
      if (!i.converted) {
        i.value = i.value * 100;
        i.converted = true;
      }
      return i;
    });
    this.covarianceMatrixTableData = this.extractHeatMapTableData(this.optimizationData.covariance_matrix.items, this.portfolio.securities.length);

    this.initViewTables();
    this.initEfficientChart();
    this.dataLoaded = true;
  }

  beforeViewTablesLoaded() {
    this.footerData.historical_returns = `${this.viewReturns.historical || 0}%`;
    this.footerData.implied_returns = `${this.viewReturns.implied || 0}%`;
    this.footerData.market_equilibrium = `${this.viewReturns.market || 0}%`;
    this.footerData.forecasted_returns = `${this.viewReturns.forecast || 0}%`;

    this.initTableFilters();
  }

  initTableFilters() {
    this.filteredForecastedReturnsTableData = this.forecastedReturnsTableData;
    this.filter = {
      sector: null,
      country: null,
      type: null
    }

    let sectors = [];
    let country = [];
    let type = [];

    this.forecastedReturnsTableData.map(d => {
      sectors.push(d.sector);
      country.push(d.country);
      type.push(d.type);
    })

    this.filterLists = {
      sectorList: [...new Set(sectors)],
      countryList: [...new Set(country)],
      typeList: [...new Set(type)],
    }
  }

  onFilterChange() {
    this.viewTablesLoaded = false;
    this.filteredForecastedReturnsTableData = this.forecastedReturnsTableData.filter((d: any) => {
      let sectorFound = true;
      let countryFound = true;
      let typeFound = true;

      if (this.filter.sector && this.filter.sector.length) {
        sectorFound = this.filter.sector.includes(d.sector);
      }

      if (this.filter.country && this.filter.country.length) {
        countryFound = this.filter.country.includes(d.country);
      }

      if (this.filter.type && this.filter.type.length) {
        typeFound = this.filter.type.includes(d.type);
      }

      if (sectorFound && countryFound && typeFound) {
        return d;
      }
    })
    setTimeout(() => {this.viewTablesLoaded = true}, 100)
  }

  reloadHistoricalTable() {
    this.viewTablesLoaded = false;
    this.historicalReturnTableData = [];
    this.marketEquilibriumTableData = [];
    this.forecastedReturnsTableData = [];
    this.impliedReturnData = [];
    this.optimizationData = this.service.fetchOptimizationData(this.portfolio.id, true);
      this.initViewTables();
   
  }

  onForecastedReturnInputChange() {
    super.onForecastedReturnInputChange();
    this.footerData.forecasted_returns = `${this.viewReturns.forecast || 0}%`;
  }

  onWebsocketSuccess(msg: any) {
    // Successfully computed the historical return tables, reload it
    window.location.reload();
  }
}
