import {Component, Injector} from '@angular/core';
import {GeneralPortfolioComponent} from "../general-portfolio/general-portfolio.component";
import {OptimizationComponent} from "../optimization/optimization.component";

import {ColumnType} from "../../components/cim-datatable/cim-datatable.component";
import * as moment from "moment";
import {high_colors} from "../../charts/general-chart/general-chart.component";
import { forkJoin, of } from 'rxjs';
import * as Highcharts from "highcharts/highstock";

@Component({
  selector: 'app-performance',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
})
export class EvaluationComponent extends GeneralPortfolioComponent {
  isPrint = false;
  selectedDiversification = 'volatilty';

  filter: any = {
    start: null,
    end: null
  };

  returnTypes = {
    historical_returns: {
      id: 'historical_returns',
      name: 'Historical Return'
    },
    implied_returns: {
      id: 'implied_returns',
      name: 'Implied Views of the Baseline Portfolio'
    },
    market_equilibrium: {
      id: 'market_equilibrium',
      name: 'Market Equilibrium Views'
    },
    forecasted_returns: {
      id: 'forecasted_returns',
      name: 'My views'
    }
  };
  isLoaded: boolean = false;
  evaluationData: any = {}
  originalReturnType = null;
  evaluationDataLoaded = true;
  resultsTableDataFooter: any[] = [];
  correlationMatrixData:any = null;
  covarianceMatrixData:any = null;

  correlationMatrixTableData:any = null;
  covarianceMatrixTableData:any = null;
  riskContributionType = 'sector_fs';
  optimPerformance: any[] = [];
  volatilityData: any = {};
  originalComboData: any = [];
  comboData: any[] = null;
  comboDates: any = {
    start: null,
    end: null
  };
  weightPieData: any[] = [];
  pieDataList: any = null;
  chart3Options: Highcharts.Options = {};

  drawDownExtraOptions = {
    yAxis: {
      opposite: false,
      minorTickInterval: 0.01,
      labels: {
        format: '{value}%',
      },
      title: {
        text: 'Volatility',
        style: {
          textTransform: 'capitalize'
        }
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        },
        lineWidth: 2,
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}%</b>',
        },
      }
    }
  }

  comboExtraOptions = {
    chart: {
      marginRight: 60,
      height: '630px',
    },
    yAxis: [
      {
        minorTickInterval: 0.01,
        labels: {
          format: '{value}%',
        },
        title: {
          text: 'Return',
          style: {
            textTransform: 'capitalize'
          }
        },
        opposite: false
      },
      {
        minorTickInterval: 0.01,
        labels: {
          format: '{value}%',
          x: -2
        },
        title: {
          text: 'Drawdown',
          x: 0,
          style: {
            textTransform: 'capitalize'
          }
        },
        opposite: true
      },
    ]
  }
  allowedTickers: any = [];
  riskElasticityData: any = null;
  riskElasticityOptions = {};

  evaluationTableColumns = [
    {id: 'metric', label: 'Metric', type: ColumnType.Simple},
  ];
  evaluationTableData: any = null;

  riskContributionData: any = null;
  riskDiversificationData: any = null;
  correlationBetaData: any = null;

  evaluationSelectList: any[] = [];
  selectedEvaluation: string = 'minimum_variance_portfolio';
  selectedEvaluationNiceName = null;
  marginalRiskContributionData: any[] = [{data:[]}];

  // Martin provided default selected list
  selectedRiskFactor: any = [
    "MSCI WORLD",
    "MSCI WORLD MOMENTUM",
    "MSCI WORLD QUALITY",
    "MSCI AC EUROPE",
    "MSCI AC EUROPE ALL CAP",
    "MSCI WORLD MINIMUM VOLATILITY (USD)",
    "MSCI EM (EMERGING MARKETS) MOMENTUM"
  ];

  riskFactors: any[] = [];

  skipList = [
    "Portfolio_Baseline",
    "Portfolio_Analytics"
  ]

  loaded = false;
  optimName: string = '';
  lineLabels = {
        efficient_portfolio_max_sharpe_ratio: 'Max Sharpe PF',
        efficient_portfolio_given_mu: 'Target Return PF',
        efficient_portfolio_given_sigma: 'Target Risk PF',
        baseline_portfolio: 'Baseline PF',
        efficient_portfolio_max_mu: 'Maximum Return',
        minimum_variance_portfolio: 'Minimum Risk'
  }

  var99: any = '';
  var95: any = '';

  constructor(injector: Injector) {
    super(injector);
  }



  getCorrelationMatrix() {
    this.service.getCorrelationMatrix(this.portfolio.id).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      resp.items = resp.items.map((data: any) => { 

         data.Var1 = this.getFundName(data.Var1);
         return data;
       });

      this.correlationMatrixData = this.extractHeatMapData(resp.items)
    })
  }

  getCovarianceMatrix() {
    this.service.getCovarianceMatrix(this.portfolio.id).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      if (resp.items) {
        resp.items = resp.items.map((i: any) => {
          i.Var1 = this.getFundName(i.Var1);
          if (!i.converted) {
            i.value = i.value * 100;
            i.converted = true;
          }
          return i;
        })
      }
      
      this.covarianceMatrixData = this.extractHeatMapData(resp.items);
    })
  }


  initPage() {
    if (!this.optimizationData) {
      
      
   // this.fetchEvaluationData();
 //   this.initEvaluationData();
      let companies = this.portfolio.securities;
      this.allowedTickers = companies.map(c => c.company_name);
    }
    this.setupData();

  }

  async setupData() {

      const results = forkJoin({
         a:  await this.service.fetchOptimizationData(this.portfolio.id),
         c : await this.service.fetchPerformancePlot(this.portfolio.id),
         v : await this.service.fetchEvaluationData(this.portfolio.id, true),
        // a : of('').pipe(delay(1500)) 
      }).subscribe(

        val => {

          this.optimizationData = val.a;
          this.evaluationData = val.v;
          this.plotData = this.convertToPercentages(val.c);
          
          this.optim = this.optim.toString() !== '' ? this.optim.toString() : this.optimizationData.optim_parameters.optim_performance;

          this.fetchEvaluationTableData()
          this.setGeneralData();
          this.initCharts()
          this.afterOptimizationDateReceived();

          this.evaluationData.evaluation_table.map((e: any) => {
           if( e.reference_portfolio == this.optim.toString() && e.portfolio == this.optim.toString()){
            if(e.metric == 'VaR99')
              this.var99 = e.value;
            if(e.metric == 'VaR')
              this.var95 = e.value;
          }

          })

     });

      
      this.isLoaded = true;
      
    }

  async fetchEvaluationData(){
    this.evaluationData =  await this.service.fetchEvaluationData(this.portfolio.id);
    
  }

  setGeneralData() {
    var optim = this.optim ? this.optim.toString() : this.optimizationData.optim_parameters.optim_performance;
    this.allowedTickers = [];
    this.optimName = this.lineLabels[optim];

    this.riskContributionData = [];
    this.marginalRiskContributionData = [{data:[]}];
    this.portfolio.symbol_performance.map((e: any) => {
      if (e.Ticker !== 'Portfolio') {
        let evalData = this.evaluationData.risk_contribution.find(a => a.Ticker == e.Ticker && a.Portfolio == optim);
        let forecastedReturns = this.optimizationData.forecasted_returns.find(a => a.Ticker == e.Ticker).forecasted_returns;
        if (evalData) {
          this.allowedTickers.push(this.getFundName(e.Ticker, false, true));
          this.riskContributionData.push({
            Ticker: this.getFundName(e.Ticker),
            riskContribution: evalData.risk_contribution*100,
            Weight: evalData.weight*100,
            Return: forecastedReturns,
            company: this.portfolio.security_overviews[e.Ticker] ? this.portfolio.security_overviews[e.Ticker].company_name : this.getFundName(e.Ticker),
          });
          // this.marginalRiskContributionData[0].data.push({
          //   company: e.reference_table && e.reference_table.name ? e.reference_table.name.substring(0, 10) + '...': this.getFundName(e.Ticker),
          //   risk: parseFloat(((evalData.weight * evalData.risk_contribution)*100).toFixed(2)),
          //   return: parseFloat(((evalData.weight * forecastedReturns)*100).toFixed(2)),
          // });
        }
      }
    });


  

    let baselinePorfio = {}; 

    this.optimPerformance.push(baselinePorfio);
    var optim = this.optim.toString() !== '' ? this.optim.toString() : this.optimizationData.optim_parameters.optim_performance;
    for(let prop of ['optim_performance', "optim_performance_unconstrained", "optim_performance_cost_of_constrained"]){
          this.optimPerformance[prop] = this.portfolio[prop].filter(d => d.view_name === 'forecasted_returns' && d.Portfolio === optim) ;

    }

    if(optim == 'baseline_portfolio'){
      
       baselinePorfio = {
        'Expected_Return' : this.optimizationData.forecasted_returns.filter(d => d.Ticker == 'Portfolio')[0].forecasted_returns,
        'Std_Deviation' : this.optimizationData.historical_returns.filter(d => d.Ticker == 'Portfolio')[0].volatility,
        'Sharpe_Ratio': 1,
        'view_name': 'forecasted_returns',
        'Portfolio': 'baseline_portfolio'

      }
      this.optimPerformance['optim_performance'].push( baselinePorfio);
    }

    this.resultsTableDataFooter = [];
    this.optimizationData.forecasted_returns.map((data: any, index) => {
      let companyName = this.portfolio.security_overviews[data.Ticker] ? this.portfolio.security_overviews[data.Ticker].company_name : this.getFundName(data.Ticker);
      if(data.Ticker === 'Portfolio'){
        this.resultsTableDataFooter.push({
          companyName: companyName,
          expectedReturn: (this.optimPerformance['optim_performance'][0].Expected_Return*100).toFixed(2) + "%",
          historicalReturn: (this.optimizationData.historical_returns[index].historical_returns*100).toFixed(2) + "%",
          volatility: (this.optimPerformance['optim_performance'][0].Std_Deviation*100).toFixed(2) + "%",
          sharpe: (this.optimPerformance['optim_performance'][0].Sharpe_Ratio).toFixed(2),
          allocation: "100%"
        });
      }

    })


  }


  initEvaluationData() {
    this.selectedEvaluationNiceName = this.service.convertEvaluationType(this.selectedEvaluation.split('_').join(' '));
    if (this.portfolio && !this.evaluationSelectList.length) {
      // Filter out benchmarks
      this.evaluationSelectList = this.evaluationData.evaluation_optim_performance.filter((data: any) => {
        if (data.id.length > 6) {
          return data;
        }
      });
      this.evaluationSelectList = this.service.fixEvaluationTypeNames(this.evaluationSelectList);
      this.checkModifiedWeights();
    }
    if (!this.plotData) {
      this.fetchPerformancePlot()
    }
    if (this.evaluationData.evaluation_table) {
      this.riskFactors = []
      let addedIdList = [];
      this.evaluationData.evaluation_table.map((d: any) => {
        if (!this.skipList.includes(d.portfolio)) {
          if (!addedIdList.includes(d.portfolio)){
            this.riskFactors.push({id: d.portfolio, name: d.portfolio})
            addedIdList.push(d.portfolio)
          }
        }
      })
    }
  }

 

  afterOptimizationDateReceived() {
    this.optimizationData.correlation_matrix.items = this.optimizationData.correlation_matrix.items.map((i: any) => {
       i.Var1 = this.getFundName(i.Var1, false);
       i.Var1Full = this.getFundName(i.Var1, false, true);

       i.Var2 = this.getFundName(i.Var2, false);
       i.Var2Full = this.getFundName(i.Var2, false, true);


       return i;
    });

    this.correlationMatrixTableData = this.extractHeatMapTableData(this.optimizationData.correlation_matrix.items, Math.sqrt(this.optimizationData.correlation_matrix.items.length));

    this.optimizationData.covariance_matrix.items = this.optimizationData.covariance_matrix.items.map((i: any) => {
      i.Var1 = this.getFundName(i.Var1, false);
      i.Var1Full = this.getFundName(i.Var1, false, true);

      i.Var2 = this.getFundName(i.Var2, false);
      i.Var2Full = this.getFundName(i.Var2, false, true);
      if (!i.converted) {
        i.value = i.value * 100;
        i.converted = true;
      }
      return i;
    });
    this.covarianceMatrixTableData = this.extractHeatMapTableData(this.optimizationData.covariance_matrix.items, Math.sqrt(this.optimizationData.covariance_matrix.items.length));

    this.checkModifiedWeights();
  }

  checkModifiedWeights() {
    if (this.optimizationData && this.optimizationData.historical_returns.length && this.evaluationSelectList && this.evaluationSelectList.length) {
      if (!this.optimizationData.modified_optim_weights.length) {
        this.evaluationSelectList = this.evaluationSelectList.filter(e => e.id !== 'my_port');
      }
    }
  }

  afterPlotDataReceived() {
    this.originalReturnType = `${this.optimizationData.optim_parameters.return_type}`;
    if (!this.evaluationTableData) {
      this.fetchEvaluationTableData();
    }
  }

  getLabelOfSelectedEvaluation(evaluationID) {
    const selected = this.evaluationSelectList.filter(f => f.id == evaluationID)[0];
    return selected ? selected.name : evaluationID
  }

  fetchEvaluationTableData() {
    this.loaded = false;
    this.selectedEvaluationNiceName = this.service.convertEvaluationType(this.selectedEvaluation.split('_').join(' '));

    this.pieDataList = null;
    this.weightPieData = [];
//    this.riskContributionData = null;
    this.correlationBetaData = null;
    this.riskDiversificationData = null;
    this.evaluationTableColumns = [
      {id: 'metric', label: 'Metric', type: ColumnType.Simple},
    ];
    this.evaluationTableData = null;

    this.service.getEvaluationData(this.portfolio.id, this.optim).subscribe((resp: any) => {
      let cumReturns = [];
      let drawdowns = [];
      let volatility = [];

      resp.map((data: any) => {
        let value = +(data.value*100).toFixed(2);
        let date = moment(data.date).unix()*1000;

        if (data.variable == 'drawdown') {
          drawdowns.push([date, value])
        }
        if (data.variable == 'cumulative_returns') {
          cumReturns.push([date, value])
        }
        if (data.variable == 'rolling_std_deviation') {
          if (value > 0) { // skip 0 from start
            volatility.push([date, value])
          }
        }


      })
      this.originalComboData = [
       {
          name: 'Drawdown',
          data: drawdowns,
          type: 'line',
          yAxis: 1
        }];

      this.comboData = JSON.parse(JSON.stringify(this.originalComboData)); // Do not mess with original data
      this.volatilityData = {
        title: '',
        series: [{
          name: "Portfolio",
          type: 'line',
          data: volatility
        }]
      };
      this.initCharts();
    })
  }

  extractPlotData(list) {
    let result = [];
    Object.entries(list).map(([name, data]:[string,any]) => {
      if (name === 'Portfolio') {
        let series = {
          name: name,
          data: data.map(d => {
            if (d[1]) {
              d[1] = +(d[1].toFixed(2));
            }
            return d
          }),
          type: 'line'
        }
        result.push(series)
      }
    })
    return result;
  }

  collectWeights() {
    let weights = [];
    // load the proper weights
// Optimized weight
      this.portfolio.optim_weights.map((d: any) => {
        if (d.view_name == 'forecasted_returns' ) {
          weights.push({
            Ticker: d.Ticker,
            weight: +(d[this.optim]*100).toFixed(2),
          })
        }
      })
    
    return weights;
  }

  onRiskFactorChange() {
    this.loaded = false;
    this.cacheFactors();
    this.setRiskFactorRelatedContents()
  }

  initDiversificationChart() {
    this.loaded = false;
    this.riskDiversificationData = {
      categories: [],
      series: []
    };
    const categories = [];
    const chartData = [];

    this.evaluationData.diversification_effect.map((data: any) => {
      if (data.portfolio_name == this.selectedEvaluation && data.type == this.selectedDiversification) {
        categories.push(data.variable);
        if (this.selectedDiversification == 'sharpe') {
          chartData.push(+(data.value).toFixed(2));
        } else {
          chartData.push(+(data.value * 100).toFixed(2));
        }
      }
    });

    this.riskDiversificationData = {
      categories,
      series: [
        {
          name: '',
          type: "column",
          data: chartData,
        }
      ]
    };
    this.chart3Options.xAxis = {
      categories: this.riskDiversificationData.categories,
      gridLineWidth: 0
    };
    if (this.selectedDiversification == 'sharpe') {
      this.chart3Options.yAxis = {
        title: {
          text: ''
        },
        labels: {
          format: '{value}'
        },
        minorGridLineWidth: 0,
      };
      this.chart3Options.tooltip = {
        valueSuffix: '',
        valueDecimals: 2,
      };
      this.chart3Options.plotOptions = {
        column: {
          dataLabels: {
            enabled: true,
            format: '{y}'
          }
        }
      };
    } else {
      this.chart3Options.yAxis = {
        title: {
          text: ''
        },
        labels: {
          format: '{value}%'
        },
        minorGridLineWidth: 0,
      };
      this.chart3Options.tooltip = {
        valueSuffix: '%',
        valueDecimals: 2,
      };
      this.chart3Options.plotOptions = {
        column: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        }
      };
    }
    setTimeout(() => {this.loaded = true;}, 20);
  }

  initPieChartLists() {
    let sectors: any = {};
    let countries: any = {};
    let industries: any = {};
    const itemCount = this.weightPieData.length;
    const calculatePercentages = (count, list) => {
      const result = [];
      Object.entries(list).map(([key, val]) => {
        result.push({
          name: key,
          y: val
        });
      });
      return result;
    }


    this.weightPieData.map(d => {
      const company = this.portfolio.security_overviews[d.ticker];
      if (!company) {
        return ;
      }
      // collect sectors
      if (!sectors[company.sector_fs]) {
        sectors[company.sector_fs] = 0;
      }
      sectors[company.sector_fs] += d.y;

      // collect industries
      if (!industries[company.industry_fs]) {
        industries[company.industry_fs] = 0;
      }
      industries[company.industry_fs] += d.y;

      // collect countries
      if (!countries[company.country_hq_name]) {
        countries[company.country_hq_name] = 0;
      }
      countries[company.country_hq_name] += d.y;
    });

    this.pieDataList = {
      weights: this.weightPieData,
      country: calculatePercentages(itemCount, countries),
      sector: calculatePercentages(itemCount, sectors),
      industry: calculatePercentages(itemCount, industries)
    };
  }

  initCharts() {
    this.weightPieData = [];
    const weights = this.collectWeights();
    const securityCount = weights.length;
    let industries = [];
    const baseColor = '#D24A43';
    const greenColor = '#3CA370';

    var i = 0;       
    this.riskContributionData.map((data: any) => {
      if (data.riskContribution > 0) {
        this.weightPieData.push({
          name: data.company + ":" +data.riskContribution.toFixed(2) + "%",
          value:  1, //data.riskContribution,
          risk: data.riskContribution.toFixed(2),
          ticker: data.Ticker.slice(0, 8),
          color: Highcharts.color(baseColor).brighten((0.5/securityCount) * i).get()

        })
        i += 1;
      }
    })
    
    for (let i = this.weightPieData.length; i > this.weightPieData.length/2; i--) {
                this.weightPieData[i-1].color = Highcharts.color(greenColor).brighten((-0.1/this.weightPieData.length) * i).get();
                }
    if(this.weightPieData.length % Math.sqrt(this.weightPieData.length) > 0){
      for(let i = 0; i < this.weightPieData.length % Math.sqrt(this.weightPieData.length); i++){
        this.weightPieData.push({name: "-----", value: 1, risk: 0, ticker: "ZERO--", color: Highcharts.color('#fff').get()});
      }
    }
    //   if(this.weightPieData.length > 16 && this.weightPieData.length % 6 > 0){
    //   for(let i = 0; i < this.weightPieData.length % 6; i++){
    //     this.weightPieData.push({name: "-----", value: 1, risk: 0, ticker: "ZERO--", color: Highcharts.color('#fff').get()});
    //   }
    // }
  //  this.weightPieData.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
    

    this.initPieChartLists();

    // Risk contribution
    let riskCategories = [];
    let ws = [];
    let risks = [];
    // this.evaluationData.risk_contribution.map((data: any) => {
    //   // Only add selected evaluatino data
    //   if (data.Portfolio == this.selectedEvaluation) {
    //     let company = this.portfolio.security_overviews[data.Ticker];
    //     let companyName = (company && company.id) ? company.company_name : data.Ticker;
    //     if (companyName) {
    //       riskCategories.push(companyName);
    //       ws.push(+(data.weight * 100).toFixed(2));
    //       risks.push(+(data.risk_contribution * 100).toFixed(2));
    //     }
    //   }
    // })
    // riskCategories = [...new Set(riskCategories)];

    // this.riskContributionData = {
    //   categories: riskCategories,
    //   series: [
    //     {
    //       name: 'Weight',
    //       type: "column",
    //       data: ws
    //     },
    //     {
    //       name: 'Risk',
    //       type: "column",
    //       data: risks
    //     }
    //   ]
    // }

    this.initDiversificationChart();
    this.setRiskFactorRelatedContents();

    // this.chart3Options = {
    //   chart: {
    //     height: '500px'
    //   },
    //   credits: {
    //     enabled: false
    //   },
    //   title: {
    //     text: ''
    //   },
    //   colors: high_colors,
    //   legend: {
    //     enabled: false,
    //   },
    //   yAxis: {
    //     title: {
    //       text: ''
    //     },
    //     labels: {
    //       format: '{value}%'
    //     },
    //     minorGridLineWidth: 0,
    //   },
    //   xAxis: {
    //     categories: this.riskDiversificationData.categories,
    //     gridLineWidth: 0
    //   },
    //   tooltip: {
    //     valueSuffix: '%',
    //     valueDecimals: 2,
    //   },
    //   plotOptions: {
    //     column: {
    //       dataLabels: {
    //         enabled: true,
    //         format: '{y}%'
    //       }
    //     }
    //   }
    // };
  }

  setRiskFactorRelatedContents() {
    this.evaluationTableColumns = [
      {id: 'metric', label: 'Metric', type: ColumnType.Simple},
    ];
    this.evaluationTableData = null;
    // Evaluation table
    let columns = new Set([]);
    let tableData: any = {};
    const needPercentage = [
      'Annualized Return',
      'Annualized Std Dev',
      'VaR'
    ]

    //Correlation and beta agains risk factors chart
    let correlationCategories = new Set([]);
    let correlations = [];
    let betas = [];

    this.evaluationData.evaluation_table.map((data: any) => {
      // Skip portfolio data
      if (data.portfolio == 'Portfolio' || data.portfolio == 'Portfolio_Analytics' || data.portfolio == 'Baseline Portfolio') {
        return false;
      }

      if (data.reference_portfolio != this.selectedEvaluation) {
        return false;
      }

      let label = data.portfolio;

      if (
        this.skipList.includes(label) ||
        this.selectedRiskFactor.includes(label) ||
        this.selectedEvaluation == data.portfolio
      ) {
        if (label == this.selectedEvaluation) {
          label = this.selectedEvaluationNiceName;
        }

        if (data.portfolio == 'Portfolio_Baseline') {
          return false;
        }

        if (data.portfolio != this.selectedEvaluation) {
          if (data.metric === 'Correlation Portfolio') {
            correlations.push(+data.value.toFixed(2))
            correlationCategories.add(label);
          }
          if (data.metric === 'Beta Portfolio') {
            betas.push(+data.value.toFixed(2))
          }
        }

        columns.add(label);

        if (!tableData[data.metric]) {
          let metricLbl = data.metric;
          if (metricLbl == 'Annualized Std Dev') {
            metricLbl = 'Annualized Volatility';
          }
          tableData[data.metric] = {
            metric: metricLbl
          }
        }
        tableData[data.metric][label] = needPercentage.includes(data.metric) ?
          `${(data.value*100).toFixed(2)}%` :
          data.value.toFixed(2);
      }
    })

    const moveSecondToFirst = (list) => {
      const temp = list[0];
      list[0] = list[1]
      list[1] = temp;
      return list;
    }


    let datatableColumns = [];
    columns.forEach(c => {
      let label = c;
      if (c == 'Portfolio_Baseline') {
        label = 'Baseline Portfolio';
      }
      datatableColumns.push({ id: c, label: label, type: ColumnType.Simple, textRight: true })
    })

    // Correlation chart column ordering fix
    let correlactionCategoriesArray = [...correlationCategories];

    this.evaluationTableColumns = [
      ...this.evaluationTableColumns,
      ...datatableColumns
    ];

    this.evaluationTableData = Object.values(tableData);
    this.correlationBetaData = {
      categories: correlactionCategoriesArray,
      series: [
        {
          name: 'Correlation Portfolio',
          type: "column",
          data: correlations
        },
        {
          name: 'Beta Portfolio',
          type: "column",
          data: betas
        }
      ]
    }
    this.initRiskContributionCategoryChart();

    setTimeout(() =>{ this.loaded = true}, 100)
  }

  initRiskContributionCategoryChart() {
    this.riskElasticityData = null;
    let elasticityData = [];
    this.evaluationData.risk_elasticity.map((data: any) => {
      if (data.Portfolio == this.selectedEvaluation) {
        if (data.category == this.riskContributionType && data.risk_inc > 0) {
          elasticityData[data.category_name] = +(data.risk_inc).toFixed(2)
        }
      }
    })
    this.riskElasticityData = [
      {
        name: '',
        type: "column",
        data: Object.values(elasticityData),
      }
    ];
    this.riskElasticityOptions = {
      chart: {
        height: '300px'
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: Object.keys(elasticityData),
        gridLineWidth: 0,
      },
      yAxis: {
        title: {
          text: ''
        },
        minorGridLineWidth: 0
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{y}%'
          }
        },
        series: {
          lineWidth: 2,
          dataGrouping: {
            enabled: false
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y}%</b>',
          },
        }
      },
    }

  }

  onDateChange(prop) {
    this.filter[prop] = this.filter[prop].format('YYYY-MM-DD');
  }

  /* Drawdown & return chart date changed, adjust data */
  onComboChartDateChange(event: any) {
    this.comboDates = event;
    this.comboData = null;
    const start = event.start || null;
    const end = event.end || null;
    const originalComboData = JSON.parse(JSON.stringify(this.originalComboData)); // Do not mess with original data

    const returns = this.util.normalizeDataToStartDate(start, originalComboData[0].data);

    let filteredReturns = [...returns];
    if (start) {
      filteredReturns = filteredReturns.filter(i => i[0] >= start);
    }
    if (end) {
      filteredReturns = filteredReturns.filter(i => i[0] <= end);
    }
    setTimeout(() => {this.comboData =
      [
        {name: "Return", data: returns, type: "area"},
        {name: "Drawdown", data: this.util.calculateDrawdown(filteredReturns), type: "line", yAxis: 1}
      ];}, 100);
  }

  onRiskReportClick() {
    const optimParams: any = {
      optim_parameters: this.optimizationData.optim_parameters
    };
    optimParams.action = 'risk-analysis';
    this.service.saveRiskData(this.portfolio.id, optimParams).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      this.service.notificationService.open("Risk parameters saved. Calculation started...");
      this.openWebsocket();
    })
  }

  onWebsocketSuccess(msg: any) {
    this.service.notificationService.open('Risk calculation finished. Reloading...');
    setTimeout(() => {window.location.reload()}, 500);
  }

  onRiskTypeChange() {
    this.pieDataList = null;
    this.initCharts();
    if (this.originalReturnType !== this.optimizationData.optim_parameters.return_type) {
      this.service.notificationService.open("Selected view changed. Please click 'Update' and wait for the computation to finish.");
    }
  }

  cacheFactors() {
    const cache = {
      portfolio: this.portfolio.id,
      evaluation: this.selectedEvaluation,
      factors: this.selectedRiskFactor
    };
    this.service.storage.set(this.service.cacheFactorKey, cache);

  }

  restoreFactors() {
    const cache = this.service.storage.get(this.service.cacheFactorKey);
    if (cache) {
      if (cache.portfolio === this.portfolio.id) {
        this.selectedEvaluation = cache.evaluation;
        this.selectedRiskFactor = cache.factors;
      } else {
        // different portfolio remove cache
        this.service.storage.remove(this.service.cacheFactorKey);
      }
    }
  }

  onEvaluationTypeChange() {
    this.cacheFactors();
    this.fetchEvaluationTableData();
  }

  onSaveAsNewPortfolio() {
    const security_ids: any = {};
    this.pieDataList.weights.map((w: any) => {
      security_ids[w.id] = { weight: (w.y / 100)};
    });

    const portfolio: any = {
      name: 'Optimized - ' + this.portfolio.name,
      description: this.portfolio.description,
      start_date: this.portfolio.start_date,
      type: this.portfolio.type,
      currency_id: this.portfolio.currency_id,
      visibility: this.portfolio.visibility,
      currency_hedging: {},
      cost_of_rebalancing: this.portfolio.cost_of_rebalancing,
      stress_test_events: this.portfolio.stress_test_events,
      rebalancing_type: this.portfolio.rebalancing_type,
      weighting_type: 'manual',
      etfPercent: this.portfolio.etfPercent,
      security_ids,
    };

    if (this.portfolio.currency_hedging && this.portfolio.currency_hedging.length) {
      const hedging = {};
      this.portfolio.currency_hedging.map(h => {
        hedging[h.currency_code] = h.hedge_pct;
      });
      portfolio.currency_hedging = hedging;
    }

    this.service.saveItem(portfolio).subscribe((resp: any) => {
      if (resp.id) {
        this.service.notificationService.open("Portfolio saved, calculation started");
        this.openCreateWebsocket(resp.id);
      }
    });
  }

  openCreateWebsocket(portfolioID) {
    this.service.openPortfolioWebsocket(portfolioID).subscribe(
      msg => {
        if (msg.success) {
          if (msg.task == 'portfolio') {
            this.service.portfolioFinished.next({id: portfolioID}) // reload portfolio
            // websocket answered success true, close socket
            const noti = this.service.notificationService.open("#"+portfolioID + " portfolio computation finished", "Open");
            noti.onAction().subscribe(() => {
              this.router.navigate(['/app/portfolio-view/'+portfolioID+'/overview']);
              setTimeout(() => {
                window.location.reload();
              }, 200)
            });
          }
        } else {
          this.service.notificationService.open("There was an error during portfolio computation.")
        }
        this.service.closeWebsocket();
      }
    );
  }


}


