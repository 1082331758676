import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import series_label from 'highcharts/modules/series-label';
import HC_Exporting from 'highcharts/modules/exporting';
import {UtilService} from "../../shared/services/util.service";
import {DatawrapperService} from "../../shared/services/datawrapper.service";
import theme from 'highcharts/themes/grid-light';
import {StorageService} from "../../shared/services/storage.service";
import {MatDialog} from "@angular/material/dialog";
import {ChartEditorDialogComponent} from "../chart-editor-dialog/chart-editor-dialog.component";
import {high_colors} from "../../charts/general-chart/general-chart.component";
import * as moment from "moment";

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LineChartComponent implements OnInit, OnChanges {

  @Input() series: any[] = [];
  @Input() fullHeight = false;
  @Input() extraOptions = {};
  @Input() chartTitle = '';
  @Input() enableDatawrapper = false;
  @Input() benchmarks: any[] = [];
  @Input() benchmark: any = null;
  @Input() loaded = false;
  @Input() chartConstructor = 'stockChart';
  @Input() startDate = null;
  @Input() endDate = null;

  @Input() editorInfos: any = null;
  @Input() isPrint = false;
   @Input() info = false;

  @Input() chartStart = null;
  @Input() chartEnd = null;
  @Input() title: string;

  @Output() afterSetExtremes: EventEmitter<any> = new EventEmitter<any>();


  chartInstance: Highcharts.Chart;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  util: UtilService;
  datawrapperService: DatawrapperService;
  storage: StorageService;
  dialog: MatDialog;
  constructor(
    private injector: Injector
  ) {
    this.util = injector.get(UtilService)
    this.datawrapperService = injector.get(DatawrapperService)
    this.storage = injector.get(StorageService)
    this.dialog = injector.get(MatDialog)

    if (typeof Highcharts === 'object') {
      theme(Highcharts);
      HC_Exporting(Highcharts);
      series_label(Highcharts);
    }
  }

  ngOnInit(): void {
    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.series) {
      this.loaded = false;
      this.series = changes.series.currentValue;
      if (this.series && this.series.length) {
        this.initChart();
      }
    }
    if (changes.startDate || changes.endDate) {
      this.updateDates();
    }
  }

  updateDates() {
    if (this.chartInstance) {
      const axis: any = this.chartInstance.xAxis[0];
      const start = this.startDate ? moment(this.startDate).unix() * 1000 : axis.dataMin;
      const end = this.endDate ? moment(this.endDate).unix() * 1000 : axis.dataMax;
      axis.setExtremes(
        start,
        end
      );
    }
  }

  initChart() {
    const updateSeries = (start, end) => {
      this.chartStart = start;
      this.chartEnd = end;
      this.afterSetExtremes.emit({start, end});
    };

    this.chartOptions = {
      chart: {
        height: this.isPrint ? '500px' : '500px',
      },
      credits: {
        enabled: false
      },
      title: {
        text: this.chartTitle
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: '',
          style: {
            textTransform: 'capitalize'
          }
        },
        min: this.chartStart || null,
        max: this.chartEnd || null,
        events: {
          afterSetExtremes(e) {
            updateSeries(e.min, e.max);
          }
        },
      },
      
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      
      yAxis: {
        gridLineColor: '#ffff',
        opposite: false,
        minorTickInterval: 0.5,
        title: {
          text: 'Price',
          style: {
            textTransform: 'capitalize',
            fontFamily: 'os400'
          }
        },
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        plotLines: [{
          value: 0,
          width: 1,
          color: '#aaa',
          zIndex: 10
        }],
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        valueDecimals: 2
      },
      colors: high_colors,
      series: this.series,
      plotOptions: {
        trendline: {
          visible: false
        },
        series: {
          lineWidth: 2,
          dataGrouping: {
            enabled: false
          },
          label: {
            enabled: true,
          },
          animation: !this.isPrint
        },
        column: {
          dataLabels: {
            enabled: true,
          }
        }
      },
      rangeSelector : {
        enabled: false
      }
    };
    if (this.fullHeight) {
      this.chartOptions.chart.height = '96%';
    }
    if (this.extraOptions) {

      this.chartOptions = {
        ...this.chartOptions,
        ...this.extraOptions
      };
    }
    setTimeout(() => {
      this.loaded = true;
    }, 10);
  }

  setChartInstance(chart: Highcharts.Chart) {
    this.chartInstance = chart;
  }

  onDatawrapperClick(title = 'Chart', portfolioName = 'Baseline Portfolio') {
    const options = this.chartOptions;
    options.title = { text: title };
    options.subtitle = { text: portfolioName }
    options.plotOptions.line = {
      marker: {
        enabled: false
      }
    };

    const listString = this.util.seriesToCSVInput(this.series, true);
    this.storage.set('CimClickedChart', listString);
    this.storage.set('CimClickedChartOptions', options);

    const dialogRef = this.dialog.open(ChartEditorDialogComponent, {
      data: {},
      panelClass: 'editor-dialog',
      width: '100%',
      height: '100%'
    });
  }
}
