<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-12">
                        <h1>{{title}}<app-info *ngIf="info" infoIndex="{{info}}"></app-info></h1>
                    </div>
                </div>
            </div>
            <div class="card-body">
<div class="heatmap-table-container">
  <table class="heatmap-table">
    <tr class="position-sticky sticky-col-top">
      <td class="sticky-corner"></td>
      <td class="text-center rotate" *ngFor="let colData of tableData">
        <div [matTooltip]="colData[0].fullName2">{{ (colData[0].fullName2.length>10)? (colData[0].fullName2 | slice:0:10)+'&mldr;':(colData[0].fullName2)   }} </div>
      </td>
    </tr>
    <tr *ngFor="let colData of tableData">
      <td class="text-right sticky-left min-width-120" [matTooltip]="colData[0].fullName2">{{ (colData[0].fullName2.length>10) ? (colData[0].fullName2 | slice:0:10)+'&mldr;':(colData[0].fullName2)   }} </td>
      <td class="text-center text-white hover-style number-col" [style.background-color]="data.color" *ngFor="let data of colData" [matTooltip]="getTooltipText(data.fullName1, data.fullName2)"
          matTooltipClass="heatmap-tip">{{data.value}}</td>
    </tr>
  </table>
</div>
            </div>
        </div>
    </div>
</div>
