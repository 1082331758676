<!-- Correlation Matrix -->
<div class="card mb-4" *ngIf="optimData && optimData.correlation_matrix">
  <div class="card-header pb-2 cursor-pointer" (click)="!isPrint ? section_expanded = !section_expanded : null">
    {{matrixDataType === 'correlation' ? 'Correlation' : 'Covariance'}} Matrix
  </div>
  <div class="card-body pb-0 expanding-content" [@detailExpand]="section_expanded ? 'expanded' : 'collapsed'">
    <div class="row">
      <div class="row w-100" *ngIf="!isPrint">
        <div class="col w-160">
          <mat-form-field class="w-100" appearance="outline" >
            <mat-label>Chart Data</mat-label>
            <mat-select [(ngModel)]="matrixDataType" name="matrix-data-type">
              <mat-option [value]="'covariance'">Covariance Matrix</mat-option>
              <mat-option [value]="'correlation'">Correlation Matrix</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col w-160">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Frequency Data</mat-label>
            <mat-select [(ngModel)]="optimData.optim_parameters.correlation_frequency">
              <mat-option *ngFor="let o of optimData.correlation_matrix.params.frequencies" [value]="o.id">
                {{o.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col w-160">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Method</mat-label>
            <mat-select [(ngModel)]="optimData.optim_parameters.correlation_method">
              <mat-option *ngFor="let o of optimData.correlation_matrix.params.methods" [value]="o.id">
                {{o.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="optimData.optim_parameters.correlation_method == 'exponential_decay' && optimData.optim_parameters" class="col w-160">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Covariance Matrix λ</mat-label>
            <input matInput [(ngModel)]="optimData.optim_parameters.covmat_lambda" type="number">
          </mat-form-field>
        </div>
        <div class="col w-160">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Shock Diagonals</mat-label>
            <input matInput value="0" type="number" min="0" max="100" [(ngModel)]="optimData.optim_parameters.correlation_shock_diagonal">
            <span matSuffix>%</span>
          </mat-form-field>
        </div>
        <div *ngIf="filterLists.countryList.length" class="col w-160">
          <app-simple-select [list]="filterLists.countryList" label="Country" [isMultiple]="true" [model]="filter.country" [isFullWidth]="true" (modelChanged)="filter.country = $event; onFilterChange()"></app-simple-select>
        </div>
        <div *ngIf="filterLists.countryList.length" class="col w-160">
          <app-simple-select [list]="filterLists.sectorList" label="Sector" [isMultiple]="true" [model]="filter.sector" [isFullWidth]="true" (modelChanged)="filter.sector = $event; onFilterChange()"></app-simple-select>
        </div>
      </div>
      <div [class.col-md-12]="isPrint" class="row">
        <app-heatmap-table *ngIf="correlationMatrixTableData && matrixDataType == 'correlation'" [dataRaw]="correlationMatrixTableData" [allowedTickers]="allowedTickers"></app-heatmap-table>
        <app-heatmap-table *ngIf="covarianceMatrixTableData && matrixDataType == 'covariance'" [dataRaw]="covarianceMatrixTableData" [allowedTickers]="allowedTickers"></app-heatmap-table>
      </div>
    </div>
  </div>
</div>
