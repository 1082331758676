import { Component, OnInit } from '@angular/core';
import {PortfolioService} from "../portfolio/portfolio.service";
import {PortfolioModel} from "../../shared/models/portfolio.model";
import {AuthService} from "../../shared/services/auth.service";
import { NavigationEnd, Router } from '@angular/router';
import {SSEComponent} from '../../components/sse/sse.component';

declare function initUserBack(email): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  portfolio = new PortfolioModel({});
  isAdmin = false;
  showFiller = false;

  public isPortfolio = false;
  public isCreatePortfolio = false;
  public isOpened = true;

  constructor(
    private router: Router,
    private service: PortfolioService,
    private auth: AuthService
  ) {
    this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          const has = event.url.includes('/portfolio') || event.url.includes('/portfolio-view/');
          if(has !== this.isPortfolio) {
            this.isOpened = false;
            setTimeout(() => {
              this.isOpened = true;
              if(has) {
                this.isPortfolio = true;
              } else {
                this.isPortfolio = false;
              }
            }, 700)
          }
          if(!has) {
            this.service.clear();
          }
        }
    });}

  ngOnInit() {
    this.service.portfolio$.subscribe(res => {
      this.portfolio = res
    });
    this.auth.getCurrentUser().subscribe(user => {
      if(user){
       const email = user && user.email ? user.email : '';
      // initUserBack(email);
      this.isAdmin = user.isSuperAdmin() || user.isAdmin();
     }
     else
     {
       this.router.navigate(['app/lo']);
     }
    })
  }
}
