import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ConstraintService } from "../../shared/services/constraint.service";
import {UtilService} from "../../shared/services/util.service";

@Component({
  selector: 'app-constraint-setter',
  templateUrl: './constraint-setter.component.html',
  styleUrls: ['./constraint-setter.component.scss']
})
export class ConstraintSetterComponent implements OnInit {

  @Input() sectors: any[] = []
  @Input() countries: any[] = []
  @Input() assets: any[] = []
  @Input() portfolio: any;
  @Input() constraints: any[] = []
  @Input() isSummary: boolean = false
  @Output() optimize = new EventEmitter();

  shorts = false;

  activeCons: any = {};
  list: any[] = [];
  showOptimize = false;

  bulkEdit = {
    enabled: false,
    min: null,
    max: null
  };

  selectedCons: any[] = [];

  types = [
    { id: 'asset', name: 'Asset' },
    { id: 'sector', name: 'Sector' },
    { id: 'country', name: 'Country' }
  ]

  operators = ['=', '>', '<', '<=', '>=']

  constructor(
    private service: ConstraintService,
    private util: UtilService
  ) { }

  ngOnInit(): void {
  }

  onSelect(c) {
    if (this.util.isItemInArray(c, this.selectedCons)) {
      this.selectedCons = this.util.findAndReplaceItemInArray(c, this.selectedCons, true);
    } else {
      this.selectedCons.push(c);
    }
  }

  selectAll() {
    this.constraints.forEach(c => {
      this.onSelect(c);
      c.selected = !c.selected;
    })
  }

  deleteBulk() {
    this.selectedCons.map(c => {
      this.deleteCon(c);
    });
    this.selectedCons = [];
  }

  editBulk() {
    this.bulkEdit.enabled = true;
  }

  discardBulk() {
    this.bulkEdit = {
      enabled: false,
      min: null,
      max: null
    };
  }

  saveBulk() {
    this.selectedCons = this.selectedCons.map(c => {
      c.min = this.bulkEdit.min;
      c.max = this.bulkEdit.max;
      c.type = c.type.id;
      c.items = c.items.map(i => +i.id);
      c.portfolio_id = this.portfolio.id;
      return c;
    });
    this.selectedCons.map(c => {
      this.doBulkSave(c);
    });
    this.discardCon();
    this.discardBulk();
  }

  onSaveEachChange() {
    this.activeCons.saveEach = !this.activeCons.saveEach;
  }

  onTypeChange() {
    this.list = []
    switch (this.activeCons.type) {
      case 'asset':
        this.list = this.assets;
        break;
      case 'sector':
        this.list = this.sectors;
        break;
      case 'country':
        this.list = this.countries;
        break;
      default:
        this.list = [];
    }
  }

  initActiveCon()
  {
    return {
      type: this.types[0],
      portfolio_id: this.portfolio.id,
    }
  }

  onAddConstraint() {
    this.saveCon(false)
    this.activeCons = this.initActiveCon()
    this.list = this.assets;
  }

  doBulkSave(c) {
    this.service.create(c).subscribe(resp => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error);
      }
      if (c.id) {
        this.constraints = this.util.findAndReplaceItemInArray(resp, this.constraints);
      } else {
        this.constraints.push(resp);
      }
    })
  }

  saveEachCon() {
    const con: any = {};
    this.activeCons.items.map(i => {
      con.type = this.activeCons.type;
      con.portfolio_id = this.activeCons.portfolio_id;
      con.items = [i];
      con.min = this.activeCons.min;
      con.max = this.activeCons.max;

      this.doBulkSave(con);
    })
  }

  saveCon(discard = true) {
    if (this.activeCons.saveEach) {
      return this.saveEachCon();
    }

    if (this.activeCons.type && this.activeCons.items) {
      this.service.create(this.activeCons).subscribe(resp => {
        if (resp.error) {
          return this.service.notificationService.open(resp.error);
        }
        if (this.activeCons.id) {
          this.constraints = this.util.findAndReplaceItemInArray(resp, this.constraints);
        } else {
          this.constraints.push(resp);
        }
        this.showOptimize = true;
        if (discard) {
          this.discardCon();
        }
      })
    }
  }

  editCon(c) {
    this.activeCons = {...c};
    this.activeCons.type = c.type.id;
    this.onTypeChange();
    this.activeCons.items = c.items.map(i => +i.id);
    this.activeCons.portfolio_id = this.portfolio.id;
  }

  deleteCon(c) {
    this.service.delete(c).subscribe(resp => {
      this.constraints = this.constraints.filter(con => con.id !== c.id);
    })
  }

  discardCon() {
    this.activeCons = {};
  }
}
