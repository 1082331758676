import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {StorageService} from '../../../shared/services/storage.service';
import {MatDialog} from '@angular/material/dialog';
import {AssetTableColumnPresetComponent} from './asset-table-column-preset/asset-table-column-preset.component';
import {Filters} from './Filters';

@Component({
  selector: 'app-asset-table-column-visibility-filter',
  templateUrl: './asset-table-column-visibility-filter.component.html',
  styleUrls: [
    '../../../components/filter-select/filter-select.component.scss',
    './asset-table-column-visibility-filter.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AssetTableColumnVisibilityFilterComponent implements OnInit {
  @Input() columnVisibility: any = null;
  @Input() quantList: any[] = [];
  @Input() assetType = 'stock';
  @Input() dynamicColumns: any[] = [];

  @Output() columnChanged: EventEmitter<any> = new EventEmitter<any>();

  activeSavedFilter: any = [];

  accordionData: any[] = [];

  stockFilters = Filters.stockFilters;
  stockFiltersIndexes = Filters.stockFiltersIndexes;
  etfFilters = Filters.etfFilters;
  etfFiltersIndexes = Filters.etfFiltersIndexes;
  fundFilters = Filters.fundFilters;
  fundFiltersIndexes = Filters.fundFiltersIndexes;
  savedFilters: any[] = [];
  favoriteFilters: any[] = [];
  filtersIndex = {};

  searchText: string = null;
  filtersCleared = false;
  activePanelsAmount: any[] = [];
  expandedPanels: any[] = [];

  constructor(
    private storage: StorageService,
    private dialog: MatDialog
  ) { 

    this.storage.sideMenuObjectChange.subscribe((data) => {


        let assetType = data.assetType;
        if(assetType !== this.assetType){
          this.assetType = assetType;
          this.initAll()
        }
      
    })
  }

  ngOnInit(): void {
    this.initAll();
  }

  initAll() {

    if(!this.assetType)
      this.assetType = 'stock';

    this.restoreVisibility();
    this.initFavoriteFilters();
    this.initSavedFilters();

    if (this.assetType === 'stock') {
      this.initStockColumns();
    }else if (this.assetType === 'fund') {
      this.initFundColumns();
    }else {
      this.initETFColumns();
    }
    this.updateDynamicColumns();
  }

  initETFColumns() {
    this.accordionData = [];
    this.accordionData.push(...this.etfFilters);
    this.filtersIndex = this.etfFiltersIndexes;
  }
   initFundColumns() {
    this.accordionData = [];
    this.accordionData.push(...this.fundFilters);
    this.filtersIndex = this.fundFiltersIndexes;
  }
  initStockColumns() {
    this.accordionData = [];
    this.accordionData.push(...this.stockFilters);
    this.filtersIndex = this.stockFiltersIndexes;
  }

  initSavedFilters() {
    const savedPresets = this.storage.get(`${this.storage.columnPresetStorageKey}${this.assetType}`);
    if (savedPresets) {
      this.savedFilters = (savedPresets);
    }
  }

  initFavoriteFilters() {
    const favoriteFilters = this.storage.get(`${this.storage.favoriteFiltersStorageKey}${this.assetType}`);
    if (favoriteFilters) {
      this.favoriteFilters.push(...favoriteFilters);
    }
  }

  isFavorite(filter) {
    return this.favoriteFilters.some(f => f.id === filter.id);
  }

  opendialog() {
    let dialogRef = this.dialog.open(AssetTableColumnPresetComponent, {
      height: '650px',
      width: '600px',
      data: {
        filtersList: this.assetType === 'stock' ? Filters.stockFilters : Filters.fundFilters,
        assetType: this.assetType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.initSavedFilters();
    });
  }

  cacheVisibility(fromFavorites = false) {
    const key = `${this.storage.visibilityStorageKey}${this.assetType}`;
    this.storage.set(key, this.columnVisibility);
    this.updateDynamicColumns(fromFavorites, fromFavorites);
  }

  removeCache() {
    const key = `${this.storage.visibilityStorageKey}${this.assetType}`;
    this.storage.remove(key);
    this.updateDynamicColumns();
  }

  onResetFilters() {
    Object.entries(this.columnVisibility).map(([key, value]) => {
      this.columnVisibility[key] = false;
    });
    this.storage.visabilityChange.emit(this.columnVisibility);
    this.removeCache();
    this.activePanelsAmount = [];
  }

  updateDynamicColumns(updateCount = true, fromFavorites = false) {
    if (fromFavorites) {
      this.activePanelsAmount = [];
    }
    this.dynamicColumns = [];
    const columns: any[] = [];
    const visibleIDList = [];

    Object.entries(this.columnVisibility).map(([key, val]) => {
      if (val === true) {
        visibleIDList.push(key);
      }
    });

    this.accordionData.map((category: any) => {
      category.items.map((item: any) => {
        if (updateCount && this.columnVisibility[item.id] === true) {
          this.toggleFilterCount(category.title, item.id);
        }
        if (category.title === 'Default Filters' && item.default) {
         // return false;
        }
        if (visibleIDList.includes(item.id)) {
          // columns.push(item);
          columns[visibleIDList.indexOf(item.id)] = item;
        }
      });
    });


    this.storage.visabilityChange.emit(this.columnVisibility);
    this.dynamicColumns = columns;
    this.storage.filterSelected.emit(this.dynamicColumns);
    this.columnChanged.emit(this.dynamicColumns);
  }

  applyPreset(preset) {
    this.activePanelsAmount = [];
    if (Object.values(this.activeSavedFilter).every((v) => v === false)) {
      this.restoreVisibility();
      this.updateDynamicColumns();
      this.filtersCleared = false;
      return;
    } else {
      if (!this.filtersCleared) {
        for (const key of Object.keys(this.columnVisibility)) {
          delete this.columnVisibility[key];
        }
        const columns: any[] = [];
        this.columnChanged.emit(columns);
      }

      this.filtersCleared = true;
    }


    Object.entries(this.activeSavedFilter).map(([key, val]) => {
      if (val) {
        this.activatePreset(preset);
      } else {
        this.deActivatePreset(preset);
      }
    });

    this.storage.visabilityChange.emit(this.columnVisibility);
    this.updateDynamicColumns();
  }

  activatePreset(preset): void {
    Object.entries(preset.columns).map(([key, val]) => {
      if (val === true) {
        this.columnVisibility[key] = true;
      }
    });
    this.storage.visabilityChange.emit(this.columnVisibility);
  }

  deActivatePreset(preset): void {
    Object.entries(preset.columns).map(([key, val]) => {
      if (val === true) {
        this.columnVisibility[key] = false;
      }
    });
    this.storage.visabilityChange.emit(this.columnVisibility);
  }

  restoreVisibility() {
    Object.entries(this.columnVisibility).map(([k, val]) => {
      this.columnVisibility[k] = false;
    });
    const key = `${this.storage.visibilityStorageKey}${this.assetType}`;
    const visibility = this.storage.get(key) || {};
    Object.entries(visibility).map(([k, val]) => {
      if (val === true) {
        this.columnVisibility[k] = true;
      }
    });
    this.storage.visabilityChange.emit(this.columnVisibility);
  }

  removeSavedPreset(filter): void {
    this.savedFilters.splice(filter, 1);

    this.storage.set(`${this.storage.columnPresetStorageKey}${this.assetType}`, this.savedFilters);
  }

  editSavedPreset(i) {
    const filter = this.savedFilters[i];
    this.dialog.open(AssetTableColumnPresetComponent, {
      height: '650px',
      width: '600px',
      data: {
        filtersList: this.assetType === 'stock' ? Filters.stockFilters : Filters.etfFilters,
        name: filter.name,
        columns: filter.columns,
        index: i,
        assetType: this.assetType
      }
    });
  }

  toggleFavorite(filter) {
    if (!this.isFavorite(filter)) {
      this.favoriteFilters.push(filter);
      this.favoriteFilters.sort((a, b) => this.filtersIndex[a.id] - this.filtersIndex[b.id]);
    } else {
      this.favoriteFilters = this.favoriteFilters.filter(f => f.id !== filter.id);
    }
    this.storage.set(`${this.storage.favoriteFiltersStorageKey}${this.assetType}`, this.favoriteFilters);
  }

  toggleFilterCount(panel, filterId) {
    if (this.columnVisibility[filterId] === true) {
      if (this.activePanelsAmount[panel] !== undefined) {
        this.activePanelsAmount[panel]++;
      } else {
        this.activePanelsAmount[panel] = 1;
      }
    } else {
      if (this.activePanelsAmount[panel] !== undefined) {
        this.activePanelsAmount[panel]--;
      }
    }
  }

  scrollTo(id) {
    const el = document.getElementById(id);
    if (!this.expandedPanels[el.dataset.panel]) {
      this.expandedPanels[el.dataset.panel] = true;
    }
    setTimeout(() => {
      el.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, 200);
  }
}
