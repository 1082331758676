import {Component, Input, OnInit} from '@angular/core';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @Input() infoIndex: number;

  close(){
    this.open = false;
  }

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: -100,
      offsetY: 200,
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
      panelClass: null,
    },
  ];

  infoContent = {

    1 : "<b> Currency: </b>The currency you have chosen for the portfolio.<br /><b># Assets: </b> The number of assets in your backtested portfolio. <br />\ <b>Created:</b> The date when the portfolio was created. <br /><b>Last Updated:</b> The last time you made a change to the portfolio.",
    2: "<b> Filters: </b>  Here, you can add filters that help you screen for Equities or Mutual Funds with specific characteristics. You can also drag and drop filters to arrange them in the order you prefer in the table. The \"Create Preset\" option allows you to group filters and activate them with a single click. The available filters differ between Equities and Mutual Funds.<br /> \
       <b> Equity Selection: </b>This section displays all equities based on your selected filters.<br />\
       <b> Fund Selection:</b>  This section displays all Funds based on your selected filters.<br />\
       <b> Checked Only:</b>  This section shows all your selected Equities or Funds within their respective tabs.<br />\
       <b> Overview: </b> This section displays all assets you have selected.<br />\
       <b>Earliest possible start date:</b> The earliest start date for a backtest simulation, determined by the asset with the least historical data among your selections.",
    3: "<b>Name of portfolio:</b> Name your portfolio according to a strategy, its characteristics, or a client’s name.<br />\
      <b>Description:</b> Provide detailed information about the portfolio, which can help remind you of the filters and characteristics you used to screen and select assets.<br />\
      <b>Base Currency:</b> Select the currency in which you want your portfolio to be denominated.<br />\
      <b>Start Date:</b> Set the start date for the portfolio backtest simulation. You cannot choose a date earlier than the “Earliest possible start date” or “Earliest price point”.<br />\
      <b>FX Hedging:</b> Choose whether to hedge against the currencies of the underlying assets.<br />\
      <b>Rebalancing:</b> Select if and how often the portfolio should be rebalanced: No Rebalancing, Monthly, Quarterly, or Yearly.<br />\
      <b>Cost of rebalancing (basis points):</b> Estimate the cost of executing each rebalancing, which will adjust the portfolio's performance.<br />\
      <b>Weighting:</b> You can allocate your portfolio's assets using one of three methods: “Manual,” where you personally define the weights for each asset; “Equal,” where each asset is given an identical proportion of the total investment; or “Market Cap,” where the assets are weighted based on their market capitalization, reflecting their size in the broader market.<br />\
        ",
    4: "<b>Start Date – End Date:</b> Select a specific period over which you want to see the portfolio’s backtest performance.<br />\
        <b>Select benchmark:</b> Choose from any of the 30+ indices in our database to benchmark your portfolio.<br />\
        ",
    5: "<b>Start Date:</b> The date the backtest simulation begins (can be changes in the settings).<br />\
        <b>End Date:</b> The date the backtest simulation ends (can be changes in the settings).<br />\
        <b>Rebalancing:</b> Reflects the rebalancing frequency you set when creating the portfolio.<br />\
        <b>Cost of rebalancing (basis points):</b> Based on the cost you specified when creating the portfolio.<br />\
        <b>Currency:</b> Reflects the currency selected for the portfolio.<br />\
        <b>Weighting:</b> Based on the weighting method (Manual, Equal, or Market Cap) chosen during portfolio creation.<br />\
        <b>Simulation Date:</b> The date you created the backtest simulation.<br />\
        <b>FX Hedging:</b> The currencies you chose to hedge when creating the portfolio.",
    6: "<b>Dividend Yield:</b> Average Dividend yield based on the latest closing price and monthly dividends (only calculated on equities).<br />\
          <b>P/E (Price to Earnings):</b> Based on the latest closing price and monthly EPS.<br />\
         <b> P/S (Price to Sales):</b> Calculated using the latest closing price and monthly sales (only calculated on equities).<br />\
          <b>P/B (Price to Book):</b> Average Portfolio Price to Book ratio.<br />\
          <b>Avg. Market Cap:</b> Average Portfolio market capitalization.<br />\
          <b>Avg. Enterprise Value:</b> Average Portfolio enterprise value, using diluted shares (only calculated on equities).<br />\
          <b>Avg. EBITDA:</b> Average Portfolio EBITDA (only calculated on equities).",
    7: "<b>1 Month:</b> Rolling return over the last 30 days.<br />\
            <b>YTD (Year-to-Date):</b> Return from the start of the year until now.<br />\
            <b>Total Return:</b> Total portfolio return since the beginning of the backtest simulation.<br />\
            <b>Annualized Return:</b> Compound annual growth rate (CAGR) since the start of the backtest simulation.<br />\
            <b>CAGR 3Yr:</b> 3-year compound annual growth rate.<br />\
            <b>CAGR 5Yr:</b> 5-year compound annual growth rate.<br />\
            <b>CAGR 10Yr:</b> 10-year compound annual growth rate.",
    8: "<b>Annualized Volatility:</b> Measures the variation of the portfolio's returns, expressed as the standard deviation of returns, adjusted on an annualized basis.<br />\
        <b>Sharpe Ratio:</b> Average return minus the risk-free rate, divided by the standard deviation of the excess return, quantifying the return per unit of risk (volatility) taken.<br />\
        <b>Max Drawdown:</b> Largest single drop in the portfolio’s value from peak to trough over a specific period.<br />\
        <b>Treynor Ratio:</b> Average return minus the risk-free rate, divided by the portfolio’s beta, which measures the return per unit of systematic risk taken relative to the market.<br />\
        <b>Calmar Ratio:</b> Average return minus the risk-free rate, divided by the maximum drawdown, which quantifies the return per unit of risk associated with the largest observed peak-to-trough decline.<br />\
        <b>Tracking Error:</b> Measures the volatility of the difference between the portfolio's returns and its benchmark.<br />\
        <b>Information Ratio:</b> Evaluates the portfolio’s risk-adjusted performance relative to the benchmark.<br />\
        <b>VaR 95:</b> Value at Risk (VaR) at 95% confidence estimates the maximum expected loss for a portfolio over a specific period, with a 95% chance that the loss will not exceed this level.<br />\
        <b>VaR 99:</b> Value at Risk (VaR) at 99% confidence offers a stricter measure, estimating the maximum loss with a 99% chance that it won’t be exceeded during the given period.<br />\
        <b>Alpha:</b> A measure of the portfolio’s performance relative to its benchmark, adjusting for risk taken.<br />\
        <b>Beta:</b> Measures the portfolio's sensitivity to market movements, quantifying its systematic risk or market risk.",
    9: "<b>Risk Contribution and Allocation:</b>: Each asset's risk contribution indicates how much it contributes to the overall risk of the portfolio (100%).",
    10: "<b>Marginal Risk Contribution:</b> Marginal Risk Contribution quantifies how much the total risk of a portfolio increases when an additional unit of a particular asset is added. It reflects the sensitivity of the portfolio's total risk to changes in the exposure of that asset.",
    11: "<b>Start Date – End Date:</b> Set the period or use presets to calculate your covariance and correlation matrix.<br />\
        <b>Risk-Free Rate:</b> Set the risk-free rate to calculate the tangency portfolio on the Efficient Frontier",
    12: "<b>Chart Data:</b> View the covariance or correlation matrix.<br />\
         <b>Frequency:</b> Choose whether to calculate covariance or correlation based on daily, weekly, or monthly data.<br />\
         <b>Covariance/ Correlation Method:</b> Select whether to calculate covariance or correlation using the Historical, Exponential Decay, or GARCH method.<br />\
         <b>Exponential Decay:</b><br />\
         <b> - Lambda (λ):</b><br />\
               <ul> <li> A value between 0 and 1. </li> \
               <li> A higher lambda means that the recent observations are weighted more heavily, while a lower lambda leads to a more gradual decay of older observations.</li></ul><br />\
        <b>Shock diagonals:</b> Refers to the diagonal elements of the covariance matrix adjusted to account for the investor's confidence in their views. The higher the confidence, the smaller the \"shock\" or adjustment to the diagonal element. This adjustment helps incorporate the impact of the investor's views on the portfolio's overall risk profile. </li> </ul><br />\
        <b>Country:</b> Select to sort the covariance by country.<br />\
        <b>Sector:</b> Select to sort the covariance by sector. <br />\
        ",
    13: "<b>Implied Views:</b> Refer to the expected return views implicitly embedded in market prices or market equilibrium. These implied views are derived from observable market data, such as current asset prices, yields, or other relevant market indicators.<br />\
        <b>Market Equilibrium Returns:</b> Refer to the expected or average returns of an asset in a market where supply equals demand. E(Ri​)=Rf​+βi​(E(Rm​)−Rf​)<br />\
        <b>Top 3 Largest Holdings:</b> Refers to the top three largest holdings in your backtested portfolio and what the expected return should be with these weights compared to the market equilibrium return.<br />\
        <b>Top 3 Largest Deviations from Market Equilibrium Returns:</b> Refers to the top three largest return deviations between the implied return and the market equilibrium return.<br />\
        <b>Weight:</b> The weight of the asset in the portfolio.<br />\
        <ul><li> <b>Market Cap Weight:</b> How the portfolio would be weighted if based on the asset’s market capitalization.<br />\
         </li><li> <b>Volatility:</b> Measures the variation in asset returns, typically expressed as the standard deviation of its returns over a specific period, depending on the \"Start Date – End Date\" you set in the settings.<br />\
        </li><li> <b>Historical Returns:</b> Refers to the past performance of the asset over a specified period, depending on the \"Start Date – End Date\" you set in the settings.<br />\
        </li><li> <b>Market Equilibrium Returns:</b> Refers to the expected returns of an asset in a market where supply equals demand, depending on the \"Start Date – End Date\" you set in the settings.<br />\
        </li><li> <b>Implied Returns:</b> Refer to the expected returns that can be derived from the current prices or valuations of financial assets. These returns are implied from market prices and are used in financial analysis to assess market expectations. depending on the \"Start Date – End Date\" you set in the settings.\
        </li><li><b>Implied vs. Market Equilibrium Returns:</b> Refers to the return deviations between the implied return and the market equilibrium return.<br /> \
        </ul>",
    14: "<b>Type: Asset, Sector, or Country.</b><br />\
        <b>View:</b> Specify which asset(s), sector(s), or country(s) you want to set your view(s) on.<br />\
        <b>View Type:</b> Set whether your view is relative (against other asset(s), sector(s), or country(s)), absolute, or relative to market equilibrium.<br />\
        <b>Take:</b> If you choose the \"relative\" option in the View Type, you're expressing whether you expect the asset(s), sector(s), or country(s) to outperform or underperform a reference point.<br />\
        <b>View 2:</b> Set the reference point: asset(s), sector(s), or country(s) that you expect to be outperformed or underperformed.<br />\
        <b>Value%:</b> Indicate how much you expect the asset(s), sector(s), or country(s) to perform better or worse.<br />\
        <b>Confidence%:</b> Express how strongly you believe in the accuracy of the expected return adjustments you've provided to the model:<br />\
        - <b>Strong conviction:</b> 80% to 100%<br />\
        - <b>Moderate conviction:</b> 40% to 79%<br />\
        - <b>Limited conviction:</b> 1% to 39%<br />\
        <b>Optimize:</b> If you have made any changes, you need to optimize the portfolio again. (You can also set constraints further down the page that will need optimization if you choose to make changes, to save time you can optimize the entire portfolio when all changes has been made).<br />\
        ",
    15: "<b>Volatility:</b> Measures the variation in returns for the assets, typically expressed as the standard deviation of returns over a specific period, depending on the \"Start Date – End Date\" set in the settings.<br />\
        <b>Historical Returns:</b> Refers to the past performance of the asset over a specified period, depending on the \"Start Date – End Date\" set in the settings.<br />\
        <b>Implied Returns:</b> Refer to the expected returns that can be derived from the current prices or valuations of financial assets. These returns are implied from market prices and are used in financial analysis to assess market expectations. depending on the \"Start Date – End Date\" you set in the settings.<br />\
        <b>Market Equilibrium Returns:</b> Refers to the expected returns of an asset in a market where supply equals demand, depending on the \"Start Date – End Date\" you set in the settings.<br />\
        <b>Combined Returns:</b> Represents the overall expected returns for a portfolio, combining equilibrium market returns and investor views. The Black-Litterman model integrates your views with market equilibrium returns to generate new expected returns for the assets in the portfolio.",
    16: "<b>Constraints:</b> Set minimum and maximum weights for individual or groups of assets, sectors, or countries.<br />\
        <b>Allow Short Positions:</b> You can specify whether the model should optimize for strategies that include short positions or exclude them.<br />\
        <b>Type:</b> Asset, Sector, or Country<br />\
        <b>Asset:</b> Specify the asset(s), sector(s), or country(s)<br />\
        Express the Min Value or Max Value for the weights<br />\
        <b>Save Each:</b> Select this option if each asset, sector, or country has individual constraints; otherwise, the constraint applies to the group.<br />\
        <b>Optimize:</b> If you have made any changes, you need to optimize the portfolio again. <br />",
    17: "<b>Constrained Portfolio:</b> Shows the expected return, volatility, and Sharpe ratio for the portfolio with constraints.<br />\
        <b>Unconstrained Portfolio:</b> Shows the expected return, volatility, and Sharpe ratio for the portfolio without constraints.<br />\
        <b>Cost of Constraints:</b> Displays the deviation in expected return, volatility, and Sharpe ratio between the constrained and unconstrained portfolios.<br />\
        <b>Expected Return:</b> The anticipated future returns predicted by the model based on your views and the market equilibrium for the asset or portfolio.<br />\
        <b>Expected Volatility:</b> Derived from the historical volatility over the period set in the settings.<br />\
        <b>Sharpe Ratio:</b> Measures the risk-adjusted performance of the portfolio, based on expected return and expected volatility.<br />\
        ",
    18: "<b>The Efficient Frontier chart</b> represents each potential asset in the portfolio with the \"Combined Return\" (based on \"Implied Return\" and \"Market Equilibrium Return\") and historical volatility. Based on the covariance matrix and \"Combined Return,\" the portfolio is optimized for different risk and return scenarios.<br />\
        <b>Unconstrained:</b> You can select to view the Efficient Frontier on the portfolio without any constraints.<br />\
        <b>Show Equities (Assets):</b> You can select to show or hide all assets in the Efficient Frontier graph.<br />\
        <b>Select Portfolio:</b> Here, you can choose any portfolio from the efficient frontier, with the Max Sharpe portfolio selected by default. You also have the option to select a Target Return or Risk, search for a specific portfolio, and then click \"Calculate.\" The selected portfolio will be displayed in the table and graphs below, as well as on the Risk Analysis page.<br />\
        ",
    19: "<b>Expected Return:</b> Represents the overall expected return for the assets, combining equilibrium market returns and investor views. The Black-Litterman model integrates your views with market equilibrium returns to generate new expected returns for the assets in the portfolio.<br />\
        <b>Historical Return:</b> Refers to the past performance of the asset over a specified period, depending on the \"Start Date – End Date\" set in the settings.<br />\
        <b>Volatility:</b> Measures the variation in returns for the assets, typically expressed as the standard deviation of returns over a specific period, depending on the \"Start Date – End Date\" set in the settings.<br />\
        <b>Expected Sharpe:</b> Measures the risk-adjusted performance of the assets, based on expected return and expected volatility.<br />\
        <b>Allocation:</b> The percentage of the total chosen portfolio assigned to a specific asset.<br />\
        <b>Min weight:</b> The minimum weights for individual assets set in the constraints.<br />\
       <b> Max weight:</b> The maximum weights for individual assets set in the constraints.<br />\
        ",
    20: "<b>Transition Map:</b> The graph illustrates how the allocation between assets shifts as the portfolio's volatility changes. It shows how asset weights adjust in response to varying risk levels, helping to visualize the relationship between asset allocation and overall portfolio volatility.",
    21: "<b>Portfolio Weights:</b> The percentage of the total chosen portfolio assigned to a specific asset.",
    22: "<b>Portfolio Return:</b> Represents the overall expected return for the assets, combining equilibrium market returns and investor views.",
    23: "<b>Risk Contribution and Allocation:</b> Each asset's risk contribution indicates how much it contributes to the overall risk of the portfolio (100%).",
    24: "<b>Marginal Risk Contribution:</b> It quantifies how much the risk of a portfolio increases when an additional unit of a particular asset is added, reflecting the sensitivity of the portfolio's total risk to changes in the asset's exposure.",
    25: "<b>Start Date:</b> The date the backtested portfolio starts.<br />\
        <b>Number of Assets:</b> Number of assets in the chosen portfolio from the optimization.<br />\
        <b>Expected Return:</b> Represents the overall expected return for the chosen portfolio in optimization, combining equilibrium market returns and investor views. <br />\
        <b>Expected Volatility:</b> Measures the variation in returns for the chosen portfolio in optimization (based on historical volatility).<br />\
        <b>Expected Sharpe:</b> Measures the risk-adjusted performance of the chosen portfolio in optimization, based on expected return and expected volatility.<br />\
        <b>VaR 95:</b> Value at Risk (VaR) at 95% confidence estimates the maximum expected loss for a portfolio over a specific period, with a 95% chance that the loss will not exceed this level.<br />\
        <b>VaR 99:</b> Value at Risk (VaR) at 99% confidence offers a stricter measure, estimating the maximum loss with a 99% chance that it won’t be exceeded during the given period.<br />\
        ",
    26: "<b>Risk Contribution and Allocation:</b> Each asset's risk contribution indicates how much it contributes to the overall risk of the portfolio chosen in the optimization (100%).",
    27: "<b>Covariance Matrix:</b> The covariance matrix for the chosen portfolio measures how the returns of the different assets within the portfolio move in relation to one another, helping to understand the degree of diversification and risk within the portfolio.",
    28: "<b>Correlation Matrix:</b> The correlation matrix for the chosen portfolio illustrates how closely the returns of different assets move together, helping to assess the degree of correlation and potential diversification benefits within the portfolio.",
    29: "<b>Hot Spots:</b> Refer to assets of high risk or volatility within a chosen portfolio.",
    30: "<b>Best Hedge:</b> Refers to the most effective strategy to mitigate or reduce the risk of adverse price movements in an investment or portfolio.",
    31: "<b>Drawdown:</b> Refers to the decline in the chosen portfolios value from its highest point to its lowest before recovery, often expressed as a percentage.",
    32: "<b>Volatility:</b> measures the extent of price variation of the chosen portfolio over time, typically quantified by the standard deviation of returns.",

  };
  open: boolean = false;

  ngOnInit(): void {
  }
}
